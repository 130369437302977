var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "key-figure" }, [
    _c("h4", { staticClass: "key-figure__value" }, [_vm._t("value")], 2),
    _vm._v(" "),
    _c("span", { staticClass: "key-figure__label" }, [_vm._t("label")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }