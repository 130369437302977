<template>
  <div class="tabs">
    <div class="tabs__navigation">
      <a
        class="tabs__tab"
        :class="{ 'tabs__tab--active': index === active }"
        href="#"
        @click.prevent="click(tab, index)"
        :title="tab.title"
        v-for="(tab, index) in tabs"
        :key="index"
        >{{ tab.title }}</a
      >
    </div>
    <div class="tabs__content-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Tab from './tab.vue';

const Tabs = {
  name: 'Tabs',
  props: {},
  data() {
    return {
      active: 0,
      tabs: [],
    };
  },
  computed: {},
  methods: {
    click(tab, index) {
      this.active = index;
    },
    updateActiveTab() {
      this.tabs.forEach((tab, index) => {
        tab.active = this.active === index;
      });
    },
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.active = this.tabs.findIndex((tab) => tab.$attrs.active !== undefined) || 0;
    this.updateActiveTab();
  },
  watch: {
    active() {
      this.updateActiveTab();
    },
  },
};

export { Tab, Tabs };

export default Tabs;
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/media-queries';
@import 'common/styles/text';
@import 'common/styles/variables';

.tabs {
  &__tab {
    // Based on (very similar) to home page -> category__heading style;
    @include heading--mini;
    color: $c-dark;
    display: inline-block;
    position: relative;
    padding: 0.5rem 0;
    margin: 0.75rem 2rem 0.75rem 0;
    text-align: center;

    // Based on https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size/32570813#32570813
    &:before {
      content: attr(title);
      display: block;
      font-weight: $bold-montserrat;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    // Together with below, a lot of small adjustments to make it
    // better centered (due to uneven space before especially
    // capital B). The underline should be 1px before/after each
    // first/last letter;

    // Underline;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      right: 50%;
      bottom: (0.5rem - 0.0625rem);
      height: 2px;
      background-color: $c-dark;
      transition: left 0.2s ease, right 0.2s ease;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      &:after {
        left: 0;
        right: -0.0625rem;
      }
    }

    &--active {
      font-weight: $bold-montserrat;

      // Together with below, a lot of small adjustments to make it
      // better centered (due to uneven space before especially
      // capital B). The underline should be 1px before/after each
      // first/last letter;

      // Underline;
      &:after {
        left: 0;
        right: -0.0625rem;
      }
    }
  }
}
</style>
