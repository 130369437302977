import debounce from 'lodash/debounce';

let callbacks = [];

function resizeCallback() {
  callbacks.forEach((callback) => callback());
}

let resizeCallbackDebounced;

export function windowResizeAdd(callback) {
  if (!callbacks.length) {
    if (!resizeCallbackDebounced) resizeCallbackDebounced = debounce(resizeCallback, 400);

    window.addEventListener('resize', resizeCallbackDebounced);
  }

  return callbacks.push(callback) - 1;
}

export function windowResizeRemove(callback) {
  callbacks = callbacks.filter((c) => c !== callback);

  if (!callbacks.length) {
    window.removeEventListener('resize', resizeCallbackDebounced);
  }
}
