<template>
  <div class="quotation" :id="`quotation-${quotation.id}`">
    <div class="quotation__image-container">
      <img class="quotation__image" :src="imagePath(`images/${quotation.supplier.coverPhoto}`, 960, 250)" />
    </div>
    <div class="quotation__header-container">
      <h3 class="quotation__header">{{ quotation.supplier.title }}</h3>
      <span class="quotation__header-location"><!-- Istanbul, -->{{ quotation.supplier.country }}</span>
    </div>
    <div class="quotation__quick-facts">
      <!--
      --><router-link
        class="quotation__quick-fact link link--dark"
        :to="{ name: 'supplier', params: { name: quotation.supplier.slug } }"
      >
        <span class="quotation__quick-fact-icon" v-html="svg.fs"></span>
        {{ quotation.supplier.title }} </router-link
      ><!--
      --><a
        class="quotation__quick-fact link link--dark"
        :href="urlAddProtocol(quotation.supplier.web)"
        target="_blank"
        v-if="quotation.supplier.web"
      >
        <span class="quotation__quick-fact-icon" v-html="svg.globe"></span>
        {{ urlHideProtocol(quotation.supplier.web) }} </a
      ><!--
      --><span class="quotation__quick-fact" v-if="quotation.supplier.companyStructure == 0">
        <span class="quotation__quick-fact-icon" v-html="svg.factory"></span>
        Manufacturer </span
      ><!--
      --><span class="quotation__quick-fact" v-else>
        <span class="quotation__quick-fact-icon" v-html="svg.business"></span>
        Agent </span
      ><!--
      --><span class="quotation__quick-fact" v-if="quotation.otherBrandsProducing">
        <span class="quotation__quick-fact-icon" v-html="svg.store"></span>
        {{ quotation.otherBrandsProducing }} </span
      ><!--
    --></div>
    <div class="quotation__company-message">
      <span class="quotation__company-message-label">Message from company</span>
      <p class="quotation__company-message-content" ref="companyMessageContent" :style="companyMessageContentStyles">
        {{ quotation.messageFromSupplier }}
      </p>
      <div
        class="quotation__company-message-more-container"
        :class="{ 'quotation__company-message-more-container--open': companyMessageReadMore }"
        v-if="companyMessageMoreVisible"
      >
        <div class="quotation__company-message-more" @click="toggleCompanyMessageReadMore()">
          Read more<span
            class="quotation__company-message-more-icon"
            :class="{ 'quotation__company-message-more-icon--open': companyMessageReadMore }"
            v-html="svg.arrow"
          ></span>
        </div>
      </div>
    </div>
    <div class="quotation__gallery" v-if="quotation.images">
      <div v-if="isMounted">
        <Swiper :options="swiperOptions" ref="articlesCarousel">
          <!-- Slides -->
          <Swiper-slide v-for="image in quotation.images" :key="image.id">
            <div class="slide__image-container">
              <img class="slide__image" :src="`${$blobUrl}/${image.url}`" :alt="image.caption" />
            </div>
          </Swiper-slide>
          <!-- Controls -->
          <div class="swiper-button-prev" slot="button-prev"><span v-html="svg.bigArrow"></span></div>
          <div class="swiper-button-next" slot="button-next"><span v-html="svg.bigArrow"></span></div>
        </Swiper>
      </div>
    </div>
    <div class="quotation__main-kpis">
      <ProgressField :progress="75" class="quotation__main-kpis-price">
        <template v-slot:value>
          {{ currencyFormatter(quotation.priceFOBFCA, (quotation.currency || {}).code) || quotation.priceFOBFCA }}
        </template>
        <template v-slot:label> Price (FOB/FCA) </template>
      </ProgressField>
      <!-- TODO: FIXME / implement -->
      <div class="quotation__main-kpis-list" v-if="false">
        <span class="quotation__main-kpi"
          ><strong class="quotation__main-kpi-highlight--red">€3,91/33%</strong> above target price</span
        >
        <span class="quotation__main-kpi"><strong>Best price</strong> in Portugal</span>
        <span class="quotation__main-kpi"><strong class="quotation__main-kpi-highlight--green">Low MOQ</strong></span>
        <span class="quotation__main-kpi"><strong>Willing</strong> to do what it takes</span>
      </div>
      <div class="quotation__fs-comment">
        <span class="quotation__fs-comment-label">FindSourcing comment</span>
        <p class="quotation__fs-comment-content">{{ quotation.messageFromFindSourcing }}</p>
      </div>
    </div>
    <div class="quotation__kpis">
      <KeyFigure class="quotation__kpi">
        <template v-slot:value>
          {{ numberFormatter(quotation.moq) || quotation.moq }}
        </template>
        <template v-slot:label> MOQ </template>
      </KeyFigure>
      <KeyFigure class="quotation__kpi">
        <template v-slot:value>
          {{ currencyFormatter(quotation.sampleCost, (quotation.currency || {}).code) || quotation.sampleCost }}
        </template>
        <template v-slot:label> Sample Cost </template>
      </KeyFigure>
      <KeyFigure class="quotation__kpi">
        <template v-slot:value>
          {{
            ('' + quotation.productionLT).match(/[a-z]/i) ? quotation.productionLT : quotation.productionLT + ' days'
          }}
        </template>
        <template v-slot:label> Production L/T </template>
      </KeyFigure>
      <KeyFigure class="quotation__kpi">
        <template v-slot:value>
          {{
            ('' + quotation.sampleLTAvailableMaterial).match(/[a-z]/i)
              ? quotation.sampleLTAvailableMaterial
              : quotation.sampleLTAvailableMaterial + ' days'
          }}
        </template>
        <template v-slot:label> Sample L/T </template>
      </KeyFigure>
    </div>
    <Tabs class="quotation__tabs-container" v-if="costBreakDownData">
      <Tab title="Cost breakdown" class="cost-breakdown" active>
        <div class="ct-square-container">
          <chartist
            :ref="`cost-breakdown-chart-${quotationIndex}`"
            ratio="ct-square"
            type="Pie"
            :data="costBreakDownData"
            :options="costBreakDownOptions"
            :event-handlers="costBreakDown.eventHandlers(_self, quotationIndex)"
          >
          </chartist>
          <h4 class="ct-square-header">{{ quotation.priceFOBFCA }}</h4>
        </div>
        <div class="ct-legend">
          <div
            class="ct-legend-item"
            :class="{
              'ct-legend-item--hover':
                costBreakDown.hover.quotationIndex === quotationIndex &&
                costBreakDown.hover.sectionIndex === sectionIndex,
            }"
            v-for="(value, sectionIndex) in costBreakDownData.series"
            :key="sectionIndex"
            @mouseenter="highlightChartSection(quotationIndex, sectionIndex)"
            @mouseleave="unHighlightChartSection(quotationIndex, sectionIndex)"
          >
            <span class="ct-legend-item-name-container">
              <span
                class="ct-legend-item-color"
                :class="`ct-legend-item-color--${String.fromCharCode(97 + sectionIndex)}`"
              ></span>
              <span class="ct-legend-item-name" :title="costBreakDown.legends[sectionIndex]">{{
                costBreakDown.legends[sectionIndex]
              }}</span>
            </span>
            <span class="ct-legend-item-value" :title="`&euro;${value}`">&euro;{{ value }}</span>
          </div>
        </div>
      </Tab>
      <!-- TODO: Implement -->
      <!--<Tab title="Analysis">
        Test2
      </Tab>-->
    </Tabs>
    <div class="quotation__fs-comment" v-if="false">
      <span class="quotation__fs-comment-label">FindSourcing comment</span>
      <p class="quotation__fs-comment-content">{{ quotation.messageFromFindSourcing }}</p>
    </div>
    <div class="quotation__decision-container">
      <fs-button-main
        class="quotation__decision"
        styling="text"
        :color="quotation.response ? 'green' : 'dark'"
        @click.prevent="$emit('response', quotation.id, true)"
        ><FsButtonIcon :icon="svg.thumbUp" />Get introduced</fs-button-main
      >
      <fs-button-main
        class="quotation__decision"
        styling="text"
        :color="quotation.response === false ? 'red' : 'dark'"
        @click.prevent="$emit('response', quotation.id, false)"
        ><FsButtonIcon :icon="svg.thumbDown" />Not interested</fs-button-main
      >
    </div>
  </div>
</template>

<script>
import { imagePath } from '@lib/imgproxy';

import FsButtonMain from '@components/buttons/fs-button-main.vue';
import FsButtonIcon from 'apps/public/pages/components/fs-button-icon.vue';
import KeyFigure from 'apps/public/pages/components/key-figure.vue';
import ProgressField from 'apps/public/pages/components/progress-field.vue';
import { Tab, Tabs } from 'apps/public/pages/components/tabs.vue';

import { Chartist, chartist } from 'vue-chartist';
const Swiper = () => import('vue-swiper-ave2/src/swiper.vue');
const SwiperSlide = () => import('vue-swiper-ave2/src/slide.vue');

import formatters from 'apps/public/lib/formatters';
import { windowResizeAdd, windowResizeRemove } from 'apps/public/lib/window-resize';

import arrow from 'apps/public/img/baseline-keyboard_arrow_down-24px.svg';
import bigArrow from 'apps/public/img/arrow_back_ios-24px.svg';
import business from 'apps/public/img/business-24px.svg';
import factory from 'apps/public/img/factory.svg';
import fs from 'apps/public/img/logo-short.svg';
import globe from 'apps/public/img/public-24px.svg';
import store from 'apps/public/img/store-24px.svg';
import thumbDown from 'apps/public/img/thumb_down-24px.svg';
import thumbUp from 'apps/public/img/thumb_up-24px.svg';

export default {
  name: 'quotations-request-quotation',
  props: {
    quotation: {
      type: Object,
    },
    quotationIndex: {
      type: Number,
    },
  },
  components: { chartist, FsButtonMain, FsButtonIcon, Swiper, SwiperSlide, KeyFigure, ProgressField, Tab, Tabs },
  data() {
    return {
      svg: {
        arrow,
        bigArrow,
        business,
        factory,
        fs,
        globe,
        store,
        thumbDown,
        thumbUp,
      },
      isMounted: false,
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: true,
        slidesPerView: 2,
      },
      images: [
        'https://findsourcing.blob.core.windows.net/images/fischer-noir-reglisse-3.jpg',
        'https://findsourcing.blob.core.windows.net/images/marron-brule-fischer.jpg',
        'https://findsourcing.blob.core.windows.net/images/fischer-marron-terre-dombre-4.jpg',
        'https://findsourcing.blob.core.windows.net/images/fischer-marron-desertique-1-600x600.jpg',
        'https://findsourcing.blob.core.windows.net/images/fischer-noir-corneille-3.jpg',
        'https://findsourcing.blob.core.windows.net/images/crimping.jpg',
      ],
      costBreakDown: {
        hover: {
          quotationIndex: -1,
          sectionIndex: -1,
        },
        legends: ['Material', 'Components', 'Labelling & Packaging', 'Tooling', 'Labor costs', 'Overhead', 'Profit'],
        eventHandlers: (self, quotationIndex) => {
          return [
            {
              event: 'draw',
              fn({ type, index: sectionIndex, element }) {
                if (type === 'slice') {
                  // TODO: REVIEW if we've created a memory leak here - are these listeners ever removed?
                  // Further, what happens if you change pages while hovering?
                  element._node.addEventListener('mouseenter', () =>
                    self.highlightChartSection(quotationIndex, sectionIndex)
                  );
                  element._node.addEventListener('mouseleave', () =>
                    self.unHighlightChartSection(quotationIndex, sectionIndex)
                  );
                }
              },
            },
          ];
        },
      },
      companyMessageReadMore: false,
      windowWidth: null,
    };
  },
  computed: {
    costBreakDownData() {
      let arr = [];

      // TODO: REVIEW if we really consider all kinds of formatting here -
      // or should we change underlying data structure maybe ?
      function nullToZero(value) {
        if (value === null) return 0;

        return Number((value || '').replace(',', '.'));
      }

      arr.push(nullToZero(this.quotation.cbMaterials));
      arr.push(nullToZero(this.quotation.cbComponents));
      arr.push(nullToZero(this.quotation.cbLabellingPackaging));
      arr.push(nullToZero(this.quotation.cbTooling));
      arr.push(nullToZero(this.quotation.cbLaborCosts));
      arr.push(nullToZero(this.quotation.cbOverheads));
      arr.push(nullToZero(this.quotation.cbProfit));

      const total = arr.reduce((total, current) => total + current, 0);

      if (total > 0 && total == this.quotation.priceFOBFCA) return { series: arr };

      return false;
    },
    costBreakDownOptions() {
      return {
        chartPadding: 0,
        donut: true,
        donutWidth: '40%',
        startAngle: 0,
        total: this.quotation.priceFOBFCA,
        showLabel: false,
      };
    },
    companyMessageContentStyles() {
      if (!this.companyMessageReadMore) return {};

      return { height: this.$refs.companyMessageContent.scrollHeight + 'px' };
    },
    companyMessageMoreVisible() {
      if (!this.isMounted) return true;

      const triggerNewUpdateOnResize = this.windowWidth;

      return this.$refs.companyMessageContent.scrollHeight > this.$refs.companyMessageContent.clientHeight + 4; // Adding 10 px as margin to avoid useless minimizing
    },
  },
  methods: {
    imagePath,
    ...formatters,
    highlightChartSection(quotationIndex, sectionIndex) {
      const node =
        this.$refs[`cost-breakdown-chart-${quotationIndex}`].chart.svg._node.childNodes[sectionIndex].childNodes[0];

      node.previousStrokeWidth = node.style.strokeWidth;

      let { value: strokeWidth } = Chartist.quantity(node.previousStrokeWidth);

      node.style.strokeWidth = strokeWidth * 1.2 + 'px';

      this.costBreakDown.hover = { quotationIndex, sectionIndex };
    },
    unHighlightChartSection(quotationIndex, sectionIndex) {
      const node =
        this.$refs[`cost-breakdown-chart-${quotationIndex}`].chart.svg._node.childNodes[sectionIndex].childNodes[0];

      if (node.previousStrokeWidth) node.style.strokeWidth = node.previousStrokeWidth;

      this.costBreakDown.hover = { quotationIndex: -1, sectionIndex: -1 };
    },
    toggleCompanyMessageReadMore() {
      this.companyMessageReadMore = !this.companyMessageReadMore;
    },
    windowResize() {
      this.windowWidth = document.body.clientWidth;
    },
  },
  mounted() {
    // Lazy load image carousels
    this.isMounted = true;
    windowResizeAdd(this.windowResize);
  },
  beforeDestroy() {
    windowResizeRemove(this.windowResize);
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/media-queries';
@import 'common/styles/text';
@import 'common/styles/variables';

@import 'chartist/dist/scss/settings/_chartist-settings.scss';
@import 'common/styles/chartist-settings.scss';
@import 'chartist/dist/scss/chartist.scss';

// Based content ~swiper/src/swiper.scss
@import 'swiper/src/scss/functions';

$themeColor: $c-dark !default;
$colors: (
  white: #ffffff,
  black: #000000,
) !default;

@import 'swiper/src/components/core/core';
@import 'swiper/src/components/navigation/navigation';

.quotations-request {
  .quotation {
    padding: 1.5rem;
    margin: 1rem;
    background-color: $c-bg-light;
    box-shadow: $bs-medium; // Taken from pages/home;

    &__image-container {
      position: relative;
      height: 0;
      padding-top: 32.25%;
      overflow: hidden;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &__header-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      @include mobile-xs-only {
        display: block;
      }
    }

    &__header {
      @include heading;

      @include mobile-xs-only {
        margin-top: 0.75em;
      }
    }

    &__header-location {
      @include subtitle;
      font-size: 0.9375em;
    }

    &__quick-facts {
      margin: 0 -0.6875rem 0 -0.6875rem;
    }

    &__quick-fact {
      @include body--small;
      display: inline-flex;
      align-items: center;
      margin: 0.4375rem 0.6875rem 0 0.6875rem;
    }

    &__quick-fact-icon {
      flex-shrink: 0;
      display: inline-block;
      height: 1.125rem;
      width: 1.125rem;
      margin-right: 0.625rem;
      fill: $c-dark;
    }

    &__company-message {
      position: relative;
      margin-top: 1.5rem;
    }

    &__company-message-label {
      @include overline;
    }

    &__company-message-content {
      margin: 0 0 0 0;
      white-space: pre-wrap;
      height: 12rem;
      overflow: hidden;
      transition: height 0.5s ease;
    }

    &__company-message-more-container {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: -3rem;
      padding-top: 2rem;
      background: linear-gradient(rgba($c-bg-light, 0), rgba($c-bg-light, 0.6) 35%, rgba($c-bg-light, 1) 70%);
      background-repeat: no-repeat;
      background-position: center bottom;
      transition: background-size 5s ease;
      // FIXME: This transition doesn't really work. Since there's
      // no length unit background-size set from the start, it will
      // jump from 0 -> 100 instantly (or really, from auto, auto ->
      // 100% 0, since auto is the default value);
      // The duration is also completely off (5s?!)

      &--open {
        background-size: 100% 0;
      }
    }

    &__company-message-more {
      @include overline;
      font-size: 0.75em;
      font-weight: $bold-lato;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 2rem;
      margin: 0 auto 0 auto;
      text-align: center;
      cursor: pointer;
    }

    &__company-message-more-icon {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      fill: $c-dark;
      margin-left: 0.25rem;
      transition: transform 0.5s ease;

      &--open {
        transform: scaleY(-1);
      }
    }

    &__gallery {
      margin: 2.5rem 0.75rem 0 0.75rem;
    }

    &__main-kpis {
      display: flex;
      justify-content: space-evenly;
      margin-top: 2.5rem;

      // Temp when having comments to the right;
      @include mobile-xs-only {
        flex-wrap: wrap;
      }
    }

    &__main-kpis-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 1.25rem 0 1rem;
      padding: 0 0 1rem 0;

      @include mobile-to-tablet {
        margin-right: 0.5rem;
      }
    }

    &__main-kpis-price {
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      flex-shrink: 0;
      flex-grow: 0;

      // Temp when having comments to the right;
      margin-left: 2rem;
      margin-right: 2rem;

      @mixin big-progress() {
        height: 7.5rem;
        width: 7.5rem;

        .progress-field__value {
          @include heading;
          font-weight: $bold-montserrat;
        }
      }

      @include tablet-landscape-min {
        @include big-progress;
      }

      @include mobile-ls-only {
        @include big-progress;
      }
    }

    &__main-kpi {
      display: block;
      margin: 0.125rem 0 0.125rem 0;
    }

    &__main-kpi-highlight {
      &--red {
        color: $c-red;
      }

      &--green {
        color: $c-green;
      }
    }

    &__main-kpi {
    }

    // REFACTOR: Very much copied from above - should probably unify into single code
    &__kpis {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 0.5rem;

      @include mobile-xs-only {
        &:after {
          content: '';
          width: 100%;
          order: 5;
        }
      }
    }

    &__kpi {
      margin: 0.875rem 1rem 0 1rem;
      flex-shrink: 0;
      white-space: nowrap;

      &:nth-child(n + 3) {
        order: 10;
      }
    }

    &__tabs-container {
      margin-top: 1.75rem;

      .tabs__content-container {
        min-height: 14rem;
      }
    }

    &__fs-comment {
      margin-top: 1.5rem;

      // Temp when having comments to the right;
      flex-grow: 1;
    }

    &__fs-comment-label {
      @include overline;
    }

    &__fs-comment-content {
      margin: 0 0 0 0;
    }

    &__decision-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 2.5rem -1rem 0 -1rem;
    }

    &__decision {
      margin: 0 1rem 0.375rem 1rem;
    }
  }

  .swiper {
    &-container {
      padding: 0 1.5rem;
    }

    // Mixin for each arrow/button;
    @mixin button($left: true) {
      $factor: 1;

      @if $left {
        $factor: -1;
      }

      display: flex;
      height: 100%;
      width: 2.25rem;
      top: 0;
      margin-top: 0;
      background: $c-bg-light;
      justify-content: center;
      align-items: center;

      > span {
        width: math.div(1.9rem, 1.6);
        transition: transform 0.2s ease, opacity 0.2s ease;
        opacity: 0.8;

        @if $left {
          margin-right: 0.25rem;
        } @else {
          margin-left: 0.25rem;
        }
      }

      @if $left {
        left: 0;
      } @else {
        right: 0;
      }

      svg {
        transform: scaleX(#{$factor * -1});
        fill: $c-dark;
      }

      &:hover {
        > span {
          transform: translateX(#{$factor * 0.25rem});
          opacity: 1;
        }
      }
    }

    &-button-prev {
      @include button();
    }

    &-button-next {
      @include button(false);
    }

    &-slide {
      width: auto;
    }
  }

  .slide {
    &__image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1.5rem;
      box-sizing: content-box;
      height: 11rem;
    }

    &__image {
      max-width: 100%;
      max-height: 100%;
      flex-shrink: 0;
    }

    &__info {
      padding-top: 1.5rem;
      flex-grow: 1;

      @include tablet-landscape-min {
        box-sizing: content-box;
        max-width: 22.5rem;
        padding: 1rem 1rem (1rem - 0.25rem) calc(4.5rem + 2vw);
      }
    }

    &__heading {
      @include heading--small;
      margin: 0;
    }

    &__intro {
      @include body--small;
      color: rgba($c-dark, 0.9);
      margin: 0.25rem 0 0.75rem;
    }

    &__link {
      @include link;
      display: inline-block;
      float: right;
      padding: 0.25rem;
    }
  }

  .cost-breakdown {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include mobile-xs-only {
      flex-wrap: wrap;
    }
  }

  .ct-square-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 11rem;
    width: 11rem;
    margin: 1rem;

    @include mobile-xs-only {
      height: 14rem;
      width: 14rem;
    }
  }

  .ct-square {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .ct-square-header {
    @include heading--small;
    margin: 0;
    z-index: 1; // To make it selectable (otherwise it seems to get hidden behind the chart/svg)

    @include mobile-xs-only {
      @include heading;
    }
  }

  .ct-chart-donut {
    overflow: visible;
  }

  .ct-series {
    cursor: pointer;
  }

  .ct-legend {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 17em;
    margin: 0.5rem;

    @include mobile-xs-only {
      max-width: 20em;
    }
  }

  .ct-legend-item {
    display: inline-flex;
    justify-content: space-between;
    margin: 0;
    padding: 0.375em 0.5em 0.3125em 0.5em;
    line-height: 1;
    cursor: pointer;

    &--hover {
      font-weight: $bold-lato;

      .ct-legend-item-color {
        height: 1.0625em;
        width: 1.0625em;
        margin-right: (0.75em - 0.125em);
        margin-bottom: -0.0625em;
        left: -0.0625em;
      }
    }
  }

  .ct-legend-item-name-container {
    display: flex;
  }

  .ct-legend-item-name {
    // Based on https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size/32570813#32570813
    // See also tabs component where we use the same strategy;
    &:before {
      content: attr(title);
      display: block;
      font-weight: $bold-lato;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  .ct-legend-item-color {
    position: relative;
    height: 0.9375em;
    width: 0.9375em;
    border-radius: $br-light;
    margin-right: 0.75em;
    top: 0.0625em;

    @for $index from 1 through length($ct-series-names) {
      $name: nth($ct-series-names, $index);
      $color: nth($ct-series-colors, $index);

      &--#{$name} {
        background-color: $color;
      }
    }
  }

  .ct-legend-item-money {
    margin-left: auto;

    // Based on https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size/32570813#32570813
    // See also tabs component where we use the same strategy;
    &:before {
      content: attr(title);
      display: block;
      font-weight: $bold-lato;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
}
</style>
