<template>
  <div class="progress-field" :class="{ 'progress-field--big': big }">
    <svg class="progress-field__ring" viewBox="0 0 96 96">
      <circle class="progress-field__lane" stroke-width="4" r="46" cx="48" cy="48" fill="transparent"></circle>
      <circle
        class="progress-field__fill"
        stroke-width="4"
        r="46"
        cx="48"
        cy="48"
        fill="transparent"
        :stroke-dashoffset="dashoffset"
      ></circle>
    </svg>
    <span class="progress-field__value"><slot name="value"></slot></span>
    <span class="progress-field__label"><slot name="label"></slot></span>
  </div>
</template>

<script>
export default {
  name: 'ProgressField',
  props: {
    big: {},
    progress: {},
  },
  data() {
    return {};
  },
  computed: {
    dashoffset() {
      return ((100 - (this.progress || 0)) / 100) * -(48 * 2 * 3.14);
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/media-queries';
@import 'common/styles/text';
@import 'common/styles/variables';

// Inspired by https://css-tricks.com/building-progress-ring-quickly/ ;
// A non-svg (css only) approach: https://www.cssscript.com/pure-css-circular-percentage-bar/ ;
.progress-field {
  position: relative;
  display: flex;
  height: 6rem;
  width: 6rem;
  padding-bottom: 2rem;
  box-sizing: content-box;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  &--big {
    height: 7.75rem;
    width: 7.75rem;

    .progress-field__value {
      @include heading;
      font-weight: $bold-montserrat;
    }
  }

  &__ring {
    // Fixes IE11 issue with position being whacky - selector from http://browserhacks.com/;
    @supports (-webkit-appearance: none) {
      position: absolute;
    }
  }

  &__lane {
    top: 0;
    stroke: rgba($c-dark, 0.3);
  }

  &__fill {
    top: 0;
    stroke: $c-dark;
    stroke-dasharray: (48 * 2 * 3.14) (48 * 2 * 3.14);
    transform: rotate(270deg);
    transform-origin: 50% 50%;
  }

  &__value {
    @include heading--small;
    font-weight: $bold-montserrat;
    position: absolute;
    top: calc(50% - #{math.div(1.6rem, 1.6)});
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__label {
    @include caption--small;
    position: absolute;
    display: block;
    left: -1rem;
    right: -1rem;
    bottom: 0;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
