<template>
  <div class="key-figure">
    <h4 class="key-figure__value"><slot name="value"></slot></h4>
    <span class="key-figure__label"><slot name="label"></slot></span>
  </div>
</template>

<script>
export default {
  name: 'KeyFigure',
  props: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/media-queries';
@import 'common/styles/text';
@import 'common/styles/variables';

.key-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  &__value {
    // Based on (very similar) to home page -> category__heading style
    @include heading--small;
    display: inline-block;
    position: relative;
    padding: 0.125rem 0;
    margin: 0;

    // Together with below, a lot of small adjustments to make it
    // better centered (due to uneven space before especially
    // capital B). The underline should be 1px before/after each
    // first/last letter;

    // Underline;
    &:after {
      content: '';
      position: absolute;
      left: -0.125rem;
      right: -0.125rem;
      bottom: 0.125rem;
      height: 2px;
      background-color: $c-dark;
    }
  }

  &__label {
    @include caption--small;
    display: block;
    text-align: center;

    &:before {
      content: '\A';
    }
  }
}
</style>
