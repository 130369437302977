var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quotations-request__container" },
    [
      _c("h1", { staticClass: "request__heading" }, [
        _vm._v(_vm._s(_vm.request.productName)),
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "request__subheading" }, [
        _vm._v(_vm._s(_vm.request.productDescription)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "request__kpis" },
        [
          _c("KeyFigure", {
            staticClass: "request__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.request.productType) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Product Type ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("KeyFigure", {
            staticClass: "request__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.request.productProductionProcess) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Production Process ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("KeyFigure", {
            staticClass: "request__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.request.targetPrice && _vm.request.currency
                            ? _vm.currencyFormatter(
                                _vm.request.targetPrice,
                                _vm.request.currency.code
                              )
                            : _vm.request.qiTargetPrice || "Not specified"
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Target price FOB/FCA ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("KeyFigure", {
            staticClass: "request__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          (_vm.request.totalQuantity
                            ? _vm.numberFormatter(_vm.request.totalQuantity)
                            : "") || _vm.request.qiTotalQuantity
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Total quantity ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("KeyFigure", {
            staticClass: "request__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.request.qiSizeRangeFrom) +
                        "-" +
                        _vm._s(_vm.request.qiSizeRangeTo) +
                        " "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Size range ")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "request__details" }, [
        _c("div", {
          staticClass: "request__image",
          style: {
            backgroundImage:
              "url('" + _vm.$blobUrl + "/" + _vm.request.productPicture + "')",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "request__field request__field--product request__field--kpi",
          },
          [
            _c("dt", { staticClass: "request__field-label" }, [
              _vm._v("Production type"),
            ]),
            _vm._v(" "),
            _c("dd", { staticClass: "request__field-value" }, [
              _vm._v(_vm._s(_vm.request.productType || "-")),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "request__field request__field--product request__field--kpi",
          },
          [
            _c("dt", { staticClass: "request__field-label" }, [
              _vm._v("Production process"),
            ]),
            _vm._v(" "),
            _c("dd", { staticClass: "request__field-value" }, [
              _vm._v(_vm._s(_vm.request.productProductionProcess || "-")),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "request__field request__field--numbers request__field--kpi",
          },
          [
            _c("dt", { staticClass: "request__field-label" }, [
              _vm._v("Total quantity"),
            ]),
            _vm._v(" "),
            _c("dd", { staticClass: "request__field-value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    (_vm.request.totalQuantity
                      ? _vm.numberFormatter(_vm.request.totalQuantity)
                      : "") ||
                      _vm.request.qiTotalQuantity ||
                      "-"
                  ) +
                  "\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "request__field request__field--numbers request__field--kpi",
          },
          [
            _c("dt", { staticClass: "request__field-label" }, [
              _vm._v("Target price FOB/FCA"),
            ]),
            _vm._v(" "),
            _c("dd", { staticClass: "request__field-value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.request.targetPrice && _vm.request.currency
                      ? _vm.currencyFormatter(
                          _vm.request.targetPrice,
                          _vm.request.currency.code
                        )
                      : _vm.request.qiTargetPrice || "-"
                  ) +
                  "\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "request__field request__field--product" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Materials upper"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(_vm._s(_vm.request.productMaterialsUpper || "-")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__field request__field--numbers" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Order quantity per article"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.numberFormatter(_vm.request.qiPlannedOrderQuantity) ||
                    _vm.request.qiPlannedOrderQuantity ||
                    "-"
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__field" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Date to receive goods"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(_vm._s(_vm.request.qiDateToReceiveGoods || "-")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__field request__field--product" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Materials sole"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(_vm._s(_vm.request.productMaterialsSole || "-")),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "request__field request__field--product request__field--kpi",
          },
          [
            _c("dt", { staticClass: "request__field-label" }, [
              _vm._v("Size range"),
            ]),
            _vm._v(" "),
            _c("dd", { staticClass: "request__field-value" }, [
              _vm._v(
                _vm._s(_vm.request.qiSizeRangeFrom) +
                  "-" +
                  _vm._s(_vm.request.qiSizeRangeTo)
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "request__field request__field--numbers" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Planned articles"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(_vm._s(_vm.request.qiPlannedArticles || "-")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__field" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Shipping destination"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(_vm._s(_vm.request.qiShippingDestination || "-")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__field" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Preferred country/region"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(_vm._s(_vm.request.preferedRegion || "-")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__field request__field--numbers" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Packaging cost"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  (_vm.request.qiPackagingCost && _vm.request.currency
                    ? _vm.currencyFormatter(
                        _vm.request.qiPackagingCost,
                        _vm.request.currency.code
                      )
                    : 0) ||
                    _vm.request.qiPackagingCost ||
                    "-"
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__field" }, [
          _c("dt", { staticClass: "request__field-label" }, [
            _vm._v("Existing relationships"),
          ]),
          _vm._v(" "),
          _c("dd", { staticClass: "request__field-value" }, [
            _vm._v(_vm._s(_vm.request.existingRelationships || "-")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "request__slider-container" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "quotations-header",
          attrs: { id: "quotations-header" },
        },
        [
          _c("h2", { staticClass: "quotations-header__heading" }, [
            _vm._v("Quotations"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quotations-header__status-container" }, [
            _c("div", { staticClass: "quotations-header__status-icons" }, [
              _c("span", {
                staticClass: "quotations-header__status-icon",
                class: {
                  "quotations-header__status-icon--active":
                    _vm.request.currentStage == 1,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.lightbulb) },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "quotations-header__status-icon",
                class: {
                  "quotations-header__status-icon--active":
                    _vm.request.currentStage == 2,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.language) },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "quotations-header__status-icon",
                class: {
                  "quotations-header__status-icon--active":
                    _vm.request.currentStage == 3,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.assignment) },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "quotations-header__status-icon",
                class: {
                  "quotations-header__status-icon--active":
                    _vm.request.currentStage == 4,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.search) },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass:
                  "quotations-header__status-icon quotations-header__status-icon--smaller",
                class: {
                  "quotations-header__status-icon--active":
                    _vm.request.currentStage == 5,
                },
                domProps: { innerHTML: _vm._s(_vm.svg.thumbsUpDown) },
              }),
            ]),
            _vm._v(" "),
            _c("strong", { staticClass: "quotations-header__status-text" }, [
              _vm._v(
                _vm._s(_vm.$store.quotations.stages[_vm.request.currentStage])
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quotations-overview" },
        [
          _c("div", { staticClass: "quotations-overview__overview" }, [
            _vm.request.quotations.length && _vm.bestPrice
              ? _c(
                  "div",
                  { staticClass: "quotations-overview__in-brief" },
                  [
                    _c("ProgressField", {
                      staticClass: "quotations-overview__best-price",
                      attrs: { progress: 75 },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "value",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.currencyFormatter(
                                        _vm.bestPrice.priceFOBFCA,
                                        (_vm.bestPrice.currency || {}).code
                                      ) || _vm.bestPrice.priceFOBFCA
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v(" Best price ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3380532302
                      ),
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "quotations-overview__kpis" }, [
                      _c("span", { staticClass: "quotations-overview__kpi" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.request.quotations.length) +
                              " quotations"
                          ),
                        ]),
                        _vm._v(" received from suppliers in\n            "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.numberOfDifferentCountries) +
                              " countries"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "quotations-overview__kpi" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.shortestLeadTime) + " days"),
                        ]),
                        _vm._v(" shortest production lead time"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "quotations-overview__kpi" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.lowestMOQ) + " pairs"),
                        ]),
                        _vm._v(" minimum order quantity"),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.request.logEntries.length
              ? _c("div", { staticClass: "quotations-overview__updates" }, [
                  _c("div", { staticClass: "quotation-update" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "quotation-update__content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "quotation-update__content-header-container",
                        },
                        [
                          _c(
                            "h5",
                            { staticClass: "quotation-update__content-header" },
                            [_vm._v(_vm._s(_vm.request.logEntries[0].title))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "quotation-update__content-date" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.dateFormatter(
                                    _vm.request.logEntries[0].date,
                                    "do 'of' MMMM yyyy"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "quotation-update__content-text" },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm.request.logEntries[0].content)),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "previousUpdatesContainer",
                      staticClass: "quotation-update__previous-container",
                      style: _vm.previousUpdatesStyles,
                    },
                    _vm._l(
                      _vm.request.logEntries.slice(1),
                      function (logEntry) {
                        return _c("div", { staticClass: "quotation-update" }, [
                          _vm._m(1, true),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "quotation-update__content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "quotation-update__content-header-container",
                                },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "quotation-update__content-header",
                                    },
                                    [_vm._v(_vm._s(logEntry.title))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "quotation-update__content-date",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dateFormatter(
                                            logEntry.date,
                                            "do 'of' MMMM yyyy"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "quotation-update__content-text",
                                },
                                [_c("p", [_vm._v(_vm._s(logEntry.content))])]
                              ),
                            ]
                          ),
                        ])
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _vm.request.logEntries.length > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "quotations-overview__previous-updates",
                          on: {
                            click: function ($event) {
                              return _vm.toggleUpdatesVisibility()
                            },
                          },
                        },
                        [
                          _vm._v("\n          Previous updates"),
                          _c("span", {
                            staticClass:
                              "quotations-overview__previous-updates-icon",
                            class: {
                              "quotations-overview__previous-updates-icon--open":
                                _vm.previousUpdatesVisible,
                            },
                            domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                          }),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.request.quotations.length
            ? _c(
                "Tabs",
                { staticClass: "quotations-overview__additional-content" },
                [
                  _c(
                    "Tab",
                    { attrs: { id: "map", title: "Map", active: "" } },
                    [
                      _c("suppliers-map", {
                        staticClass: "quotations-overview__map-container",
                        attrs: {
                          data: _vm.mapData,
                          countries: _vm.countries,
                          "marker-content": _vm.markerContent,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.request.quotations.length
        ? _c(
            "div",
            { staticClass: "quotations-table" },
            [
              _c(
                "span",
                {
                  staticClass: "quotations-table__th",
                  on: {
                    click: function ($event) {
                      return _vm.sortTable("supplier.title")
                    },
                  },
                },
                [
                  _vm._v("Supplier"),
                  _c("span", {
                    staticClass: "quotations-table__th-sort-icon",
                    class: {
                      "quotations-table__th-sort-icon--active":
                        _vm.tableSort.column === "supplier.title",
                      "quotations-table__th-sort-icon--desc":
                        _vm.tableSort.column === "supplier.title" &&
                        _vm.tableSort.desc,
                    },
                    domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "quotations-table__th",
                  on: {
                    click: function ($event) {
                      return _vm.sortTable("priceFOBFCA")
                    },
                  },
                },
                [
                  _vm._v("Price"),
                  _c("span", {
                    staticClass: "quotations-table__th-sort-icon",
                    class: {
                      "quotations-table__th-sort-icon--active":
                        _vm.tableSort.column === "priceFOBFCA",
                      "quotations-table__th-sort-icon--desc":
                        _vm.tableSort.column === "priceFOBFCA" &&
                        _vm.tableSort.desc,
                    },
                    domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "quotations-table__th",
                  on: {
                    click: function ($event) {
                      return _vm.sortTable("moq")
                    },
                  },
                },
                [
                  _vm._v("MoQ"),
                  _c("span", {
                    staticClass: "quotations-table__th-sort-icon",
                    class: {
                      "quotations-table__th-sort-icon--active":
                        _vm.tableSort.column === "moq",
                      "quotations-table__th-sort-icon--desc":
                        _vm.tableSort.column === "moq" && _vm.tableSort.desc,
                    },
                    domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "quotations-table__th",
                  on: {
                    click: function ($event) {
                      return _vm.sortTable("sampleLTAvailableMaterial")
                    },
                  },
                },
                [
                  _vm._v("Sample Lead Time"),
                  _c("span", {
                    staticClass: "quotations-table__th-sort-icon",
                    class: {
                      "quotations-table__th-sort-icon--active":
                        _vm.tableSort.column === "sampleLTAvailableMaterial",
                      "quotations-table__th-sort-icon--desc":
                        _vm.tableSort.column === "sampleLTAvailableMaterial" &&
                        _vm.tableSort.desc,
                    },
                    domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "quotations-table__th",
                  on: {
                    click: function ($event) {
                      return _vm.sortTable("productionLT")
                    },
                  },
                },
                [
                  _vm._v("Lead Time"),
                  _c("span", {
                    staticClass: "quotations-table__th-sort-icon",
                    class: {
                      "quotations-table__th-sort-icon--active":
                        _vm.tableSort.column === "productionLT",
                      "quotations-table__th-sort-icon--desc":
                        _vm.tableSort.column === "productionLT" &&
                        _vm.tableSort.desc,
                    },
                    domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "quotations-table__th",
                  on: {
                    click: function ($event) {
                      return _vm.sortTable("otherBrandsProducing")
                    },
                  },
                },
                [
                  _vm._v("Reference Brands"),
                  _c("span", {
                    staticClass: "quotations-table__th-sort-icon",
                    class: {
                      "quotations-table__th-sort-icon--active":
                        _vm.tableSort.column === "otherBrandsProducing",
                      "quotations-table__th-sort-icon--desc":
                        _vm.tableSort.column === "otherBrandsProducing" &&
                        _vm.tableSort.desc,
                    },
                    domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.sortedQuotations, function (quotation, i) {
                return [
                  _c("span", { staticClass: "quotations-table__td" }, [
                    _c("span", {
                      staticClass: "quotations-table__td-icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.assignment) },
                    }),
                    _vm._v(_vm._s(quotation.supplier.title)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "quotations-table__td" }, [
                    _vm._v(
                      _vm._s(
                        _vm.currencyFormatter(
                          quotation.priceFOBFCA,
                          (quotation.currency || {}).code
                        ) || quotation.priceFOBFCA
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "quotations-table__td" }, [
                    _vm._v(
                      _vm._s(
                        _vm.numberFormatter(quotation.moq) || quotation.moq
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "quotations-table__td" }, [
                    _vm._v(
                      _vm._s(
                        quotation.sampleLTAvailableMaterial.match(/[a-z]/i)
                          ? quotation.sampleLTAvailableMaterial
                          : quotation.sampleLTAvailableMaterial + " days"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "quotations-table__td" }, [
                    _vm._v(
                      _vm._s(
                        quotation.productionLT.match(/[a-z]/i)
                          ? quotation.productionLT
                          : quotation.productionLT + " days"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "quotations-table__td" }, [
                    _vm._v(_vm._s(quotation.otherBrandsProducing)),
                  ]),
                ]
              }),
              _vm._v(" "),
              this.ie
                ? _c(
                    "style",
                    { tag: "component" },
                    [
                      _vm._v(
                        "\n      .quotations-request .quotations-table { -ms-grid-columns: minmax(12rem, 6fr) 2.5rem minmax(min-content, 2fr)\n      2.5rem minmax(min-content, 2fr) 2.5rem minmax(min-content, 2fr) 2.5rem minmax(min-content, 2fr) 2.5rem auto;\n      -ms-grid-rows:"
                      ),
                      _vm._l(_vm.request.quotations.length + 1, function (row) {
                        return [_vm._v(" auto")]
                      }),
                      _vm._v("; }\n      "),
                      _vm._v(" "),
                      _vm._l(6, function (column) {
                        return [
                          _vm._v(
                            "\n        .quotations-request .quotations-table__th:nth-child(" +
                              _vm._s(column) +
                              ") { -ms-grid-column: " +
                              _vm._s(2 * column - 1) +
                              ";\n        -ms-grid-row: 1; }\n      "
                          ),
                        ]
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.request.quotations.length + 1, function (row) {
                        return row != 1
                          ? [
                              _vm._l(6, function (column) {
                                return [
                                  _vm._v(
                                    "\n          .quotations-request .quotations-table__td:nth-child(" +
                                      _vm._s((row - 1) * 6 + column) +
                                      ") { -ms-grid-column:\n          " +
                                      _vm._s(2 * column - 1) +
                                      "; -ms-grid-row: " +
                                      _vm._s(row) +
                                      "; }\n        "
                                  ),
                                ]
                              }),
                            ]
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.request.quotations.length
        ? _c(
            "div",
            { staticClass: "quotations-container" },
            _vm._l(_vm.sortedQuotations, function (quotation, index) {
              return _c("Quotation", {
                key: quotation.id,
                attrs: { quotation: quotation, quotationIndex: index },
                on: { response: _vm.response },
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      this.ie
        ? _c(
            "style",
            { tag: "component" },
            [
              _vm._v(
                "\n    .quotations-request .quotations-container { -ms-grid-columns: 50% 50%; -ms-grid-rows:"
              ),
              _vm._l(Math.ceil(3 / 2), function (quotation) {
                return [_vm._v("\n      auto")]
              }),
              _vm._v("; }\n    "),
              _vm._v(" "),
              _vm._l(_vm.request.quotations.length, function (quotation) {
                return [
                  _vm._v(
                    "\n      .quotations-request .quotation:nth-child(" +
                      _vm._s(quotation) +
                      ") { -ms-grid-column: " +
                      _vm._s(((quotation + 1) % 2) + 1) +
                      ";\n      -ms-grid-row: " +
                      _vm._s(Math.ceil(quotation / 2)) +
                      "; }\n    "
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation-update__author" }, [
      _c("div", { staticClass: "quotation-update__author-image-container" }, [
        _c("img", {
          staticClass: "quotation-update__author-image",
          attrs: {
            src: "https://findsourcing.blob.core.windows.net/images/0-4.jpg",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "quotation-update__author-name" }, [
        _vm._v("Elias Gröndal"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "quotation-update__author" }, [
      _c("div", { staticClass: "quotation-update__author-image-container" }, [
        _c("img", {
          staticClass: "quotation-update__author-image",
          attrs: {
            src: "https://findsourcing.blob.core.windows.net/images/0-4.jpg",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "quotation-update__author-name" }, [
        _vm._v("Elias Gröndal"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }