var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "progress-field",
      class: { "progress-field--big": _vm.big },
    },
    [
      _c(
        "svg",
        {
          staticClass: "progress-field__ring",
          attrs: { viewBox: "0 0 96 96" },
        },
        [
          _c("circle", {
            staticClass: "progress-field__lane",
            attrs: {
              "stroke-width": "4",
              r: "46",
              cx: "48",
              cy: "48",
              fill: "transparent",
            },
          }),
          _vm._v(" "),
          _c("circle", {
            staticClass: "progress-field__fill",
            attrs: {
              "stroke-width": "4",
              r: "46",
              cx: "48",
              cy: "48",
              fill: "transparent",
              "stroke-dashoffset": _vm.dashoffset,
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "progress-field__value" },
        [_vm._t("value")],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "progress-field__label" },
        [_vm._t("label")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }