var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "quotation",
      attrs: { id: "quotation-" + _vm.quotation.id },
    },
    [
      _c("div", { staticClass: "quotation__image-container" }, [
        _c("img", {
          staticClass: "quotation__image",
          attrs: {
            src: _vm.imagePath(
              "images/" + _vm.quotation.supplier.coverPhoto,
              960,
              250
            ),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "quotation__header-container" }, [
        _c("h3", { staticClass: "quotation__header" }, [
          _vm._v(_vm._s(_vm.quotation.supplier.title)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "quotation__header-location" }, [
          _vm._v(_vm._s(_vm.quotation.supplier.country)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quotation__quick-facts" },
        [
          _c(
            "router-link",
            {
              staticClass: "quotation__quick-fact link link--dark",
              attrs: {
                to: {
                  name: "supplier",
                  params: { name: _vm.quotation.supplier.slug },
                },
              },
            },
            [
              _c("span", {
                staticClass: "quotation__quick-fact-icon",
                domProps: { innerHTML: _vm._s(_vm.svg.fs) },
              }),
              _vm._v("\n      " + _vm._s(_vm.quotation.supplier.title) + " "),
            ]
          ),
          _vm.quotation.supplier.web
            ? _c(
                "a",
                {
                  staticClass: "quotation__quick-fact link link--dark",
                  attrs: {
                    href: _vm.urlAddProtocol(_vm.quotation.supplier.web),
                    target: "_blank",
                  },
                },
                [
                  _c("span", {
                    staticClass: "quotation__quick-fact-icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.globe) },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.urlHideProtocol(_vm.quotation.supplier.web)) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.quotation.supplier.companyStructure == 0
            ? _c("span", { staticClass: "quotation__quick-fact" }, [
                _c("span", {
                  staticClass: "quotation__quick-fact-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.factory) },
                }),
                _vm._v("\n      Manufacturer "),
              ])
            : _c("span", { staticClass: "quotation__quick-fact" }, [
                _c("span", {
                  staticClass: "quotation__quick-fact-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.business) },
                }),
                _vm._v("\n      Agent "),
              ]),
          _vm.quotation.otherBrandsProducing
            ? _c("span", { staticClass: "quotation__quick-fact" }, [
                _c("span", {
                  staticClass: "quotation__quick-fact-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.store) },
                }),
                _vm._v(
                  "\n      " + _vm._s(_vm.quotation.otherBrandsProducing) + " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "quotation__company-message" }, [
        _c("span", { staticClass: "quotation__company-message-label" }, [
          _vm._v("Message from company"),
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            ref: "companyMessageContent",
            staticClass: "quotation__company-message-content",
            style: _vm.companyMessageContentStyles,
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.quotation.messageFromSupplier) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.companyMessageMoreVisible
          ? _c(
              "div",
              {
                staticClass: "quotation__company-message-more-container",
                class: {
                  "quotation__company-message-more-container--open":
                    _vm.companyMessageReadMore,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "quotation__company-message-more",
                    on: {
                      click: function ($event) {
                        return _vm.toggleCompanyMessageReadMore()
                      },
                    },
                  },
                  [
                    _vm._v("\n        Read more"),
                    _c("span", {
                      staticClass: "quotation__company-message-more-icon",
                      class: {
                        "quotation__company-message-more-icon--open":
                          _vm.companyMessageReadMore,
                      },
                      domProps: { innerHTML: _vm._s(_vm.svg.arrow) },
                    }),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.quotation.images
        ? _c("div", { staticClass: "quotation__gallery" }, [
            _vm.isMounted
              ? _c(
                  "div",
                  [
                    _c(
                      "Swiper",
                      {
                        ref: "articlesCarousel",
                        attrs: { options: _vm.swiperOptions },
                      },
                      [
                        _vm._l(_vm.quotation.images, function (image) {
                          return _c("Swiper-slide", { key: image.id }, [
                            _c(
                              "div",
                              { staticClass: "slide__image-container" },
                              [
                                _c("img", {
                                  staticClass: "slide__image",
                                  attrs: {
                                    src: _vm.$blobUrl + "/" + image.url,
                                    alt: image.caption,
                                  },
                                }),
                              ]
                            ),
                          ])
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "swiper-button-prev",
                            attrs: { slot: "button-prev" },
                            slot: "button-prev",
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.svg.bigArrow) },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "swiper-button-next",
                            attrs: { slot: "button-next" },
                            slot: "button-next",
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.svg.bigArrow) },
                            }),
                          ]
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quotation__main-kpis" },
        [
          _c("ProgressField", {
            staticClass: "quotation__main-kpis-price",
            attrs: { progress: 75 },
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.currencyFormatter(
                            _vm.quotation.priceFOBFCA,
                            (_vm.quotation.currency || {}).code
                          ) || _vm.quotation.priceFOBFCA
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Price (FOB/FCA) ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "quotation__main-kpis-list" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "quotation__fs-comment" }, [
            _c("span", { staticClass: "quotation__fs-comment-label" }, [
              _vm._v("FindSourcing comment"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "quotation__fs-comment-content" }, [
              _vm._v(_vm._s(_vm.quotation.messageFromFindSourcing)),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quotation__kpis" },
        [
          _c("KeyFigure", {
            staticClass: "quotation__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.numberFormatter(_vm.quotation.moq) ||
                            _vm.quotation.moq
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" MOQ ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("KeyFigure", {
            staticClass: "quotation__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.currencyFormatter(
                            _vm.quotation.sampleCost,
                            (_vm.quotation.currency || {}).code
                          ) || _vm.quotation.sampleCost
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Sample Cost ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("KeyFigure", {
            staticClass: "quotation__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          ("" + _vm.quotation.productionLT).match(/[a-z]/i)
                            ? _vm.quotation.productionLT
                            : _vm.quotation.productionLT + " days"
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Production L/T ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("KeyFigure", {
            staticClass: "quotation__kpi",
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          ("" + _vm.quotation.sampleLTAvailableMaterial).match(
                            /[a-z]/i
                          )
                            ? _vm.quotation.sampleLTAvailableMaterial
                            : _vm.quotation.sampleLTAvailableMaterial + " days"
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" Sample L/T ")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.costBreakDownData
        ? _c(
            "Tabs",
            { staticClass: "quotation__tabs-container" },
            [
              _c(
                "Tab",
                {
                  staticClass: "cost-breakdown",
                  attrs: { title: "Cost breakdown", active: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ct-square-container" },
                    [
                      _c("chartist", {
                        ref: "cost-breakdown-chart-" + _vm.quotationIndex,
                        attrs: {
                          ratio: "ct-square",
                          type: "Pie",
                          data: _vm.costBreakDownData,
                          options: _vm.costBreakDownOptions,
                          "event-handlers": _vm.costBreakDown.eventHandlers(
                            _vm._self,
                            _vm.quotationIndex
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("h4", { staticClass: "ct-square-header" }, [
                        _vm._v(_vm._s(_vm.quotation.priceFOBFCA)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ct-legend" },
                    _vm._l(
                      _vm.costBreakDownData.series,
                      function (value, sectionIndex) {
                        return _c(
                          "div",
                          {
                            key: sectionIndex,
                            staticClass: "ct-legend-item",
                            class: {
                              "ct-legend-item--hover":
                                _vm.costBreakDown.hover.quotationIndex ===
                                  _vm.quotationIndex &&
                                _vm.costBreakDown.hover.sectionIndex ===
                                  sectionIndex,
                            },
                            on: {
                              mouseenter: function ($event) {
                                return _vm.highlightChartSection(
                                  _vm.quotationIndex,
                                  sectionIndex
                                )
                              },
                              mouseleave: function ($event) {
                                return _vm.unHighlightChartSection(
                                  _vm.quotationIndex,
                                  sectionIndex
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "ct-legend-item-name-container" },
                              [
                                _c("span", {
                                  staticClass: "ct-legend-item-color",
                                  class:
                                    "ct-legend-item-color--" +
                                    String.fromCharCode(97 + sectionIndex),
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ct-legend-item-name",
                                    attrs: {
                                      title:
                                        _vm.costBreakDown.legends[sectionIndex],
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.costBreakDown.legends[sectionIndex]
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "ct-legend-item-value",
                                attrs: { title: "&euro;" + value },
                              },
                              [_vm._v("€" + _vm._s(value))]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "quotation__fs-comment" }, [
            _c("span", { staticClass: "quotation__fs-comment-label" }, [
              _vm._v("FindSourcing comment"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "quotation__fs-comment-content" }, [
              _vm._v(_vm._s(_vm.quotation.messageFromFindSourcing)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quotation__decision-container" },
        [
          _c(
            "fs-button-main",
            {
              staticClass: "quotation__decision",
              attrs: {
                styling: "text",
                color: _vm.quotation.response ? "green" : "dark",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("response", _vm.quotation.id, true)
                },
              },
            },
            [
              _c("FsButtonIcon", { attrs: { icon: _vm.svg.thumbUp } }),
              _vm._v("Get introduced"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fs-button-main",
            {
              staticClass: "quotation__decision",
              attrs: {
                styling: "text",
                color: _vm.quotation.response === false ? "red" : "dark",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("response", _vm.quotation.id, false)
                },
              },
            },
            [
              _c("FsButtonIcon", { attrs: { icon: _vm.svg.thumbDown } }),
              _vm._v("Not interested"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "quotation__main-kpi" }, [
      _c("strong", { staticClass: "quotation__main-kpi-highlight--red" }, [
        _vm._v("€3,91/33%"),
      ]),
      _vm._v(" above target price"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "quotation__main-kpi" }, [
      _c("strong", [_vm._v("Best price")]),
      _vm._v(" in Portugal"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "quotation__main-kpi" }, [
      _c("strong", { staticClass: "quotation__main-kpi-highlight--green" }, [
        _vm._v("Low MOQ"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "quotation__main-kpi" }, [
      _c("strong", [_vm._v("Willing")]),
      _vm._v(" to do what it takes"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }