// White-space as thousand separator seems to be a good generic way to avoid confusion
// between dots and commas as been investigated previously. In other words, meaning ,
// and.can be confused with each other regardless of which way it's "wrong" since they're
// both used as decimal, but since white - space isn't it's safe to use - especially
// since a reader can simply ignore them(which is very easy with blank space).

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

function adjustTimezone(date, remove) {
  let timezoneAdjustment = date.getTimezoneOffset() * 60 * 1000;

  if (remove) timezoneAdjustment = -timezoneAdjustment;

  return new Date(date.valueOf() + timezoneAdjustment);
}

export function addTimezone(date) {
  return adjustTimezone(date);
}

export function currencyFormatter(value, code) {
  const ret = new Intl.NumberFormat('en-US', { style: 'currency', currency: code || 'USD', minimumFractionDigits: 0 })
    .format(value)
    .replace(',', ' ');

  if (ret.includes('NaN')) return null;

  return ret;
}

export function dateFormatter(date, _format) {
  return format(parseISO(date), _format);
}

export function dateToServerDateString(date) {
  return removeTimezone(date).toISOString();
}

export function numberFormatter(value, { fallback, thousandSeparator } = { fallback: null, thousandSeparator: ' ' }) {
  const ret = new Intl.NumberFormat('en-US').format(value).replace(',', thousandSeparator);

  if (ret.includes('NaN')) return fallback;

  return ret;
}

// TODO: Maybe we should use some kind of generic lib for this? Or Intl.NumberFormat (see e.g. https://stackoverflow.com/questions/29255843/is-there-a-way-to-reverse-the-formatting-by-intl-numberformat-in-javascript )
export function parseStringToNumber(value, fallback = null) {
  // Already number
  if (isNumber(value) && !isNaN(value)) return value;

  // Only handle strings (in any form)
  if (!isString(value)) return fallback;

  // Handle both commas and dots
  const dotExists = value.indexOf('.') > -1;
  const commaExists = value.indexOf(',') > -1;

  // If we only have commas, then let's assume they should be treated as dots
  if (commaExists && !dotExists) value = value.replaceAll(',', '.');

  // If we use space as separator (e.g. thousand separator), remove them since they'll mess parsing (and aren't adding anything to the parsing process)
  value = value.replaceAll(' ', '');

  const result = parseFloat(value); // Compared to Number constructor, this one filters out possible other alphabetical, irrelevant characters, see e.g. https://gomakethings.com/converting-strings-to-numbers-with-vanilla-javascript/#parsefloat

  if (result !== 0 && !result) return fallback; // Not zero, but another falsy value (e.g. NaN)

  return result;
}

export function removeTimezone(date) {
  return adjustTimezone(date, true);
}

export function serverDateStringToDate(dateString) {
  return parseISO(dateString);
}

export function urlAddProtocol(value) {
  const pattern = /^((http|https):\/\/)/;
  return !pattern.test(value) ? 'http://' + value : value;
}

export function urlHideProtocol(value) {
  return value.replace(/^(https?:|)\/\//, '');
}

export function firstNameAndFirstLetterInLast(name) {
  const splittedName = name.split(' ');

  if (splittedName.length < 2) return name;

  return `${splittedName[0]} ${splittedName[1][0]}`;
}

export default {
  addTimezone,
  currencyFormatter,
  dateFormatter,
  dateToServerDateString,
  firstNameAndFirstLetterInLast,
  numberFormatter,
  removeTimezone,
  serverDateStringToDate,
  urlAddProtocol,
  urlHideProtocol,
};
