<template>
  <div class="quotations-request__container">
    <h1 class="request__heading">{{ request.productName }}</h1>
    <h3 class="request__subheading">{{ request.productDescription }}</h3>
    <div class="request__kpis">
      <KeyFigure class="request__kpi">
        <template v-slot:value>
          {{ request.productType }}
        </template>
        <template v-slot:label> Product Type </template>
      </KeyFigure>
      <KeyFigure class="request__kpi">
        <template v-slot:value>
          {{ request.productProductionProcess }}
        </template>
        <template v-slot:label> Production Process </template>
      </KeyFigure>
      <KeyFigure class="request__kpi">
        <template v-slot:value>
          {{
            request.targetPrice && request.currency
              ? currencyFormatter(request.targetPrice, request.currency.code)
              : request.qiTargetPrice || 'Not specified'
          }}
        </template>
        <template v-slot:label> Target price FOB/FCA </template>
      </KeyFigure>
      <KeyFigure class="request__kpi">
        <template v-slot:value>
          {{ (request.totalQuantity ? numberFormatter(request.totalQuantity) : '') || request.qiTotalQuantity }}
        </template>
        <template v-slot:label> Total quantity </template>
      </KeyFigure>
      <KeyFigure class="request__kpi">
        <template v-slot:value> {{ request.qiSizeRangeFrom }}-{{ request.qiSizeRangeTo }} </template>
        <template v-slot:label> Size range </template>
      </KeyFigure>
    </div>
    <div class="request__details">
      <div class="request__image" :style="{ backgroundImage: `url('${$blobUrl}/${request.productPicture}')` }"></div>
      <div class="request__field request__field--product request__field--kpi">
        <dt class="request__field-label">Production type</dt>
        <dd class="request__field-value">{{ request.productType || '-' }}</dd>
      </div>
      <div class="request__field request__field--product request__field--kpi">
        <dt class="request__field-label">Production process</dt>
        <dd class="request__field-value">{{ request.productProductionProcess || '-' }}</dd>
      </div>
      <div class="request__field request__field--numbers request__field--kpi">
        <dt class="request__field-label">Total quantity</dt>
        <dd class="request__field-value">
          {{ (request.totalQuantity ? numberFormatter(request.totalQuantity) : '') || request.qiTotalQuantity || '-' }}
        </dd>
      </div>
      <div class="request__field request__field--numbers request__field--kpi">
        <dt class="request__field-label">Target price FOB/FCA</dt>
        <dd class="request__field-value">
          {{
            request.targetPrice && request.currency
              ? currencyFormatter(request.targetPrice, request.currency.code)
              : request.qiTargetPrice || '-'
          }}
        </dd>
      </div>
      <div class="request__field request__field--product">
        <dt class="request__field-label">Materials upper</dt>
        <dd class="request__field-value">{{ request.productMaterialsUpper || '-' }}</dd>
      </div>
      <div class="request__field request__field--numbers">
        <dt class="request__field-label">Order quantity per article</dt>
        <dd class="request__field-value">
          {{ numberFormatter(request.qiPlannedOrderQuantity) || request.qiPlannedOrderQuantity || '-' }}
        </dd>
      </div>
      <div class="request__field">
        <dt class="request__field-label">Date to receive goods</dt>
        <dd class="request__field-value">{{ request.qiDateToReceiveGoods || '-' }}</dd>
      </div>
      <div class="request__field request__field--product">
        <dt class="request__field-label">Materials sole</dt>
        <dd class="request__field-value">{{ request.productMaterialsSole || '-' }}</dd>
      </div>
      <div class="request__field request__field--product request__field--kpi">
        <dt class="request__field-label">Size range</dt>
        <dd class="request__field-value">{{ request.qiSizeRangeFrom }}-{{ request.qiSizeRangeTo }}</dd>
      </div>
      <div class="request__field request__field--numbers">
        <dt class="request__field-label">Planned articles</dt>
        <dd class="request__field-value">{{ request.qiPlannedArticles || '-' }}</dd>
      </div>
      <div class="request__field">
        <dt class="request__field-label">Shipping destination</dt>
        <dd class="request__field-value">{{ request.qiShippingDestination || '-' }}</dd>
      </div>
      <div class="request__field">
        <dt class="request__field-label">Preferred country/region</dt>
        <dd class="request__field-value">{{ request.preferedRegion || '-' }}</dd>
      </div>
      <div class="request__field request__field--numbers">
        <dt class="request__field-label">Packaging cost</dt>
        <dd class="request__field-value">
          {{
            (request.qiPackagingCost && request.currency
              ? currencyFormatter(request.qiPackagingCost, request.currency.code)
              : 0) ||
            request.qiPackagingCost ||
            '-'
          }}
        </dd>
      </div>
      <div class="request__field">
        <dt class="request__field-label">Existing relationships</dt>
        <dd class="request__field-value">{{ request.existingRelationships || '-' }}</dd>
      </div>
      <div class="request__slider-container">
        <!-- TODO: FIXME - requires that we change how requests are made though! -->
        <!--<div class="request__field request__field--as-prefix">
          <dt class="request__field-label">Focus</dt>
          <dd class="request__field-value">{{ qualityPriceSlider.focus }}</dd>
        </div>
        <Slider name="moq"
                class="request__slider"
                label="Minimum Order Quantity"
                :labels="['Price', 'Quality']"
                :data="qualityPriceSlider.data"
                :disabled="true"
                v-model="qualityPriceSlider.focus" />-->
      </div>
    </div>
    <div class="quotations-header" id="quotations-header">
      <h2 class="quotations-header__heading">Quotations</h2>
      <div class="quotations-header__status-container">
        <div class="quotations-header__status-icons">
          <span
            class="quotations-header__status-icon"
            :class="{ 'quotations-header__status-icon--active': request.currentStage == 1 }"
            v-html="svg.lightbulb"
          ></span>
          <span
            class="quotations-header__status-icon"
            :class="{ 'quotations-header__status-icon--active': request.currentStage == 2 }"
            v-html="svg.language"
          ></span>
          <span
            class="quotations-header__status-icon"
            :class="{ 'quotations-header__status-icon--active': request.currentStage == 3 }"
            v-html="svg.assignment"
          ></span>
          <span
            class="quotations-header__status-icon"
            :class="{ 'quotations-header__status-icon--active': request.currentStage == 4 }"
            v-html="svg.search"
          ></span>
          <span
            class="quotations-header__status-icon quotations-header__status-icon--smaller"
            :class="{ 'quotations-header__status-icon--active': request.currentStage == 5 }"
            v-html="svg.thumbsUpDown"
          ></span>
        </div>
        <strong class="quotations-header__status-text">{{ $store.quotations.stages[request.currentStage] }}</strong>
      </div>
    </div>
    <div class="quotations-overview">
      <div class="quotations-overview__overview">
        <div class="quotations-overview__in-brief" v-if="request.quotations.length && bestPrice">
          <ProgressField :progress="75" class="quotations-overview__best-price">
            <template v-slot:value>
              {{ currencyFormatter(bestPrice.priceFOBFCA, (bestPrice.currency || {}).code) || bestPrice.priceFOBFCA }}
            </template>
            <template v-slot:label> Best price </template>
          </ProgressField>
          <div class="quotations-overview__kpis">
            <span class="quotations-overview__kpi"
              ><strong>{{ request.quotations.length }} quotations</strong> received from suppliers in
              <strong>{{ numberOfDifferentCountries }} countries</strong></span
            >
            <span class="quotations-overview__kpi"
              ><strong>{{ shortestLeadTime }} days</strong> shortest production lead time</span
            >
            <span class="quotations-overview__kpi"
              ><strong>{{ lowestMOQ }} pairs</strong> minimum order quantity</span
            >
          </div>
        </div>
        <div class="quotations-overview__updates" v-if="request.logEntries.length">
          <div class="quotation-update">
            <div class="quotation-update__author">
              <div class="quotation-update__author-image-container">
                <!-- Fix proper image taken from proper user - and fix image quality (down-sizing causes blurry-ness) -->
                <img
                  class="quotation-update__author-image"
                  src="https://findsourcing.blob.core.windows.net/images/0-4.jpg"
                />
              </div>
              <span class="quotation-update__author-name">Elias Gröndal</span>
            </div>
            <div class="quotation-update__content">
              <div class="quotation-update__content-header-container">
                <h5 class="quotation-update__content-header">{{ request.logEntries[0].title }}</h5>
                <span class="quotation-update__content-date">{{
                  dateFormatter(request.logEntries[0].date, "do 'of' MMMM yyyy")
                }}</span>
              </div>
              <div class="quotation-update__content-text">
                <p>{{ request.logEntries[0].content }}</p>
              </div>
            </div>
          </div>
          <div
            class="quotation-update__previous-container"
            ref="previousUpdatesContainer"
            :style="previousUpdatesStyles"
          >
            <div class="quotation-update" v-for="logEntry in request.logEntries.slice(1)">
              <div class="quotation-update__author">
                <div class="quotation-update__author-image-container">
                  <img
                    class="quotation-update__author-image"
                    src="https://findsourcing.blob.core.windows.net/images/0-4.jpg"
                  />
                </div>
                <span class="quotation-update__author-name">Elias Gröndal</span>
              </div>
              <div class="quotation-update__content">
                <div class="quotation-update__content-header-container">
                  <h5 class="quotation-update__content-header">{{ logEntry.title }}</h5>
                  <span class="quotation-update__content-date">{{
                    dateFormatter(logEntry.date, "do 'of' MMMM yyyy")
                  }}</span>
                </div>
                <div class="quotation-update__content-text">
                  <p>{{ logEntry.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="quotations-overview__previous-updates"
            @click="toggleUpdatesVisibility()"
            v-if="request.logEntries.length > 1"
          >
            Previous updates<span
              class="quotations-overview__previous-updates-icon"
              :class="{ 'quotations-overview__previous-updates-icon--open': previousUpdatesVisible }"
              v-html="svg.arrow"
            ></span>
          </div>
        </div>
      </div>
      <Tabs class="quotations-overview__additional-content" v-if="request.quotations.length">
        <Tab id="map" title="Map" active>
          <!-- TODO: Fix baidu map and markerContent -->
          <suppliers-map
            class="quotations-overview__map-container"
            :data="mapData"
            :countries="countries"
            :marker-content="markerContent"
          ></suppliers-map>
        </Tab>
      </Tabs>
    </div>
    <div class="quotations-table" v-if="request.quotations.length">
      <span class="quotations-table__th" @click="sortTable('supplier.title')"
        >Supplier<span
          class="quotations-table__th-sort-icon"
          :class="{
            'quotations-table__th-sort-icon--active': tableSort.column === 'supplier.title',
            'quotations-table__th-sort-icon--desc': tableSort.column === 'supplier.title' && tableSort.desc,
          }"
          v-html="svg.arrow"
        ></span
      ></span>
      <span class="quotations-table__th" @click="sortTable('priceFOBFCA')"
        >Price<span
          class="quotations-table__th-sort-icon"
          :class="{
            'quotations-table__th-sort-icon--active': tableSort.column === 'priceFOBFCA',
            'quotations-table__th-sort-icon--desc': tableSort.column === 'priceFOBFCA' && tableSort.desc,
          }"
          v-html="svg.arrow"
        ></span
      ></span>
      <span class="quotations-table__th" @click="sortTable('moq')"
        >MoQ<span
          class="quotations-table__th-sort-icon"
          :class="{
            'quotations-table__th-sort-icon--active': tableSort.column === 'moq',
            'quotations-table__th-sort-icon--desc': tableSort.column === 'moq' && tableSort.desc,
          }"
          v-html="svg.arrow"
        ></span
      ></span>
      <span class="quotations-table__th" @click="sortTable('sampleLTAvailableMaterial')"
        >Sample Lead&nbsp;Time<span
          class="quotations-table__th-sort-icon"
          :class="{
            'quotations-table__th-sort-icon--active': tableSort.column === 'sampleLTAvailableMaterial',
            'quotations-table__th-sort-icon--desc': tableSort.column === 'sampleLTAvailableMaterial' && tableSort.desc,
          }"
          v-html="svg.arrow"
        ></span
      ></span>
      <span class="quotations-table__th" @click="sortTable('productionLT')"
        >Lead&nbsp;Time<span
          class="quotations-table__th-sort-icon"
          :class="{
            'quotations-table__th-sort-icon--active': tableSort.column === 'productionLT',
            'quotations-table__th-sort-icon--desc': tableSort.column === 'productionLT' && tableSort.desc,
          }"
          v-html="svg.arrow"
        ></span
      ></span>
      <span class="quotations-table__th" @click="sortTable('otherBrandsProducing')"
        >Reference Brands<span
          class="quotations-table__th-sort-icon"
          :class="{
            'quotations-table__th-sort-icon--active': tableSort.column === 'otherBrandsProducing',
            'quotations-table__th-sort-icon--desc': tableSort.column === 'otherBrandsProducing' && tableSort.desc,
          }"
          v-html="svg.arrow"
        ></span
      ></span>

      <!-- TODO: Add row highlighting. Make clicks on rows scroll the user to the quotation in question. Probably requires re-thinking grid-gap. -->
      <template v-for="(quotation, i) in sortedQuotations">
        <span class="quotations-table__td"
          ><span class="quotations-table__td-icon" v-html="svg.assignment"></span>{{ quotation.supplier.title }}</span
        >
        <span class="quotations-table__td">{{
          currencyFormatter(quotation.priceFOBFCA, (quotation.currency || {}).code) || quotation.priceFOBFCA
        }}</span>
        <span class="quotations-table__td">{{ numberFormatter(quotation.moq) || quotation.moq }}</span>
        <span class="quotations-table__td">{{
          quotation.sampleLTAvailableMaterial.match(/[a-z]/i)
            ? quotation.sampleLTAvailableMaterial
            : quotation.sampleLTAvailableMaterial + ' days'
        }}</span>
        <span class="quotations-table__td">{{
          quotation.productionLT.match(/[a-z]/i) ? quotation.productionLT : quotation.productionLT + ' days'
        }}</span>
        <span class="quotations-table__td">{{ quotation.otherBrandsProducing }}</span>
      </template>

      <!-- IE11 grid fix -->
      <component :is="'style'" v-if="this.ie">
        <!-- Row template -->
        .quotations-request .quotations-table { -ms-grid-columns: minmax(12rem, 6fr) 2.5rem minmax(min-content, 2fr)
        2.5rem minmax(min-content, 2fr) 2.5rem minmax(min-content, 2fr) 2.5rem minmax(min-content, 2fr) 2.5rem auto;
        -ms-grid-rows:<template v-for="row in request.quotations.length + 1"> auto</template>; }
        <!-- Head -->
        <template v-for="column in 6">
          .quotations-request .quotations-table__th:nth-child({{ column }}) { -ms-grid-column: {{ 2 * column - 1 }};
          -ms-grid-row: 1; }
        </template>
        <!-- Body -->
        <template v-for="row in request.quotations.length + 1" v-if="row != 1">
          <template v-for="column in 6">
            .quotations-request .quotations-table__td:nth-child({{ (row - 1) * 6 + column }}) { -ms-grid-column:
            {{ 2 * column - 1 }}; -ms-grid-row: {{ row }}; }
          </template>
        </template>
      </component>
    </div>
    <div class="quotations-container" v-if="request.quotations.length">
      <Quotation
        :quotation="quotation"
        :quotationIndex="index"
        v-for="(quotation, index) in sortedQuotations"
        :key="quotation.id"
        @response="response"
      />
    </div>
    <!-- IE11 grid fix -->
    <component :is="'style'" v-if="this.ie">
      <!-- Row template -->
      .quotations-request .quotations-container { -ms-grid-columns: 50% 50%; -ms-grid-rows:<template
        v-for="quotation in Math.ceil(3 / 2)"
      >
        auto</template
      >; }
      <!-- Quotations -->
      <template v-for="quotation in request.quotations.length">
        .quotations-request .quotation:nth-child({{ quotation }}) { -ms-grid-column: {{ ((quotation + 1) % 2) + 1 }};
        -ms-grid-row: {{ Math.ceil(quotation / 2) }}; }
      </template>
    </component>
  </div>
</template>

<script>
import KeyFigure from 'apps/public/pages/components/key-figure.vue';
import ProgressField from 'apps/public/pages/components/progress-field.vue';
import suppliersMap from 'apps/public/pages/components/map/suppliers-map.vue';
import { Tab, Tabs } from 'apps/public/pages/components/tabs.vue';

import Quotation from './quotation.vue';

import uniq from 'lodash/uniq';
import { imagePath } from '@lib/imgproxy';

import checkIfIE from 'apps/public/lib/checkIfIE';
import formatters from 'apps/public/lib/formatters';

import assignment from 'apps/public/img/assignment-24px.svg';
import arrow from 'apps/public/img/baseline-keyboard_arrow_down-24px.svg';
import language from 'apps/public/img/language-24px.svg';
import lightbulb from 'apps/public/img/lightbulb-outline.svg';
import search from 'apps/public/img/search-24px.svg';
import thumbsUpDown from 'apps/public/img/thumbs_up_down-24px.svg';

export default {
  name: 'quotations-request',
  async load({ store, route }) {
    const id = route.params.id;

    const { request } = await store.quotations.getRequest(id);
    const { exchangeRates } = await store.currency.loadMainCurrencies({ exchangeRates: true });

    store.head.title = `FindSourcing – Quotation Overview`;
  },
  components: { KeyFigure, ProgressField, Quotation, suppliersMap, Tab, Tabs },
  data() {
    return {
      ie: false,
      svg: {
        assignment,
        arrow,
        language,
        lightbulb,
        search,
        thumbsUpDown,
      },
      qualityPriceSlider: {
        data: ['Dirt cheap', 'Price', 'Balance', 'Quality', 'Top quality'],
        focus: 'Quality',
      },
      previousUpdatesVisible: false,
      tableSort: {
        column: 'priceFOBFCA',
        desc: false,
      },
    };
  },
  computed: {
    previousUpdatesStyles() {
      if (!this.previousUpdatesVisible) return { height: 0 };

      return { height: this.$refs.previousUpdatesContainer.scrollHeight + 'px' };
    },
    request() {
      return this.$store.quotations.fullRequests[this.$route.params.id];
    },
    bestPrice() {
      const best = this.request.quotations.reduce(
        (best, quotation, index) => {
          const price = Number(quotation.priceFOBFCA);
          let convertedPrice = price;

          if ((quotation.currency || {}).code !== best.code) {
            convertedPrice = this.$store.currency.convert(convertedPrice, quotation.currency || {}, best.code);
          }

          if (convertedPrice < best.price && price > 0) return { price, index, code: (quotation.currency || {}).code };

          return best;
        },
        { price: 999, index: -1, code: 'USD' }
      );

      return best.index < 0 ? null : this.request.quotations[best.index];
    },
    numberOfDifferentCountries() {
      return uniq(this.request.quotations.map((quotation) => quotation.supplier.country)).length;
    },
    shortestLeadTime() {
      return this.request.quotations.reduce((shortestLeadTime, quotation) => {
        return quotation.productionLT < shortestLeadTime && quotation.productionLT > 0
          ? quotation.productionLT
          : shortestLeadTime;
      }, 999);
    },
    lowestMOQ() {
      return (
        this.request.quotations.reduce((lowestMOQ, quotation) => {
          return quotation.moq < lowestMOQ && quotation.moq > 0 ? quotation.moq : lowestMOQ;
        }, 999999) || 1
      );
    },
    sortedQuotations() {
      const isNumber = ['priceFOBFCA', 'moq', 'sampleLTAvailableMaterial', 'productionLT'].includes(
        this.tableSort.column
      );
      const isCurrency = ['priceFOBFCA'].includes(this.tableSort.column);

      return [...this.request.quotations].sort((a, b) => {
        let aVal = this.dotNotationToValue(a, this.tableSort.column);
        let bVal = this.dotNotationToValue(b, this.tableSort.column);

        if (isNumber) {
          aVal = Number(aVal);
          bVal = Number(bVal);

          // Do not bother with desc true/false here since
          // we always want these in the bottom since they
          // really are non-valid numbers
          if (Number.isNaN(aVal)) return 1;
          if (Number.isNaN(bVal)) return -1;
          if (aVal === 0) return 1;
          if (bVal === 0) return -1;
        }

        if (isCurrency && a.currency && b.currency && a.currency.code !== b.currency.code) {
          bVal = this.$store.currency.convert(bVal, b.currency, a.currency.code);
        }

        if (aVal === bVal) {
          // Use id as secondary sort to ensure consistent sorting
          if (a.id > b.id) return this.tableSort.desc ? -1 : 1;
          return this.tableSort.desc ? 1 : -1;
        }

        if (!aVal) return this.tableSort.desc ? -1 : 1;
        if (!bVal) return this.tableSort.desc ? 1 : -1;

        // Ensure string to avoid weird comparison between e.g. null and strings
        if (!isNumber) {
          aVal = ('' + (aVal || '')).toLowerCase();
          bVal = ('' + (bVal || '')).toLowerCase();
        }

        if (aVal > bVal) return this.tableSort.desc ? -1 : 1;
        return this.tableSort.desc ? 1 : -1;
      });
    },
    mapData() {
      let data = [];
      console.log(this.request.quotations);
      this.request.quotations.forEach((q) => {
        if (q.supplierAddress.coordinates) {
          data.push({
            id: q.supplier.id,
            quotationId: q.id,
            latitude: q.supplierAddress.coordinates.split(', ')[0],
            longitude: q.supplierAddress.coordinates.split(', ')[1],
          });
        }
      });

      return data;
    },
    countries() {
      return uniq(this.request.quotations.map((q) => q.supplier.country).filter((c) => c));
    },
  },
  methods: {
    ...formatters,
    dotNotationToValue(obj, property) {
      const properties = property.split('.');
      let val = obj;

      properties.forEach((property) => (val = (val || {})[property]));

      return val;
    },
    toggleUpdatesVisibility() {
      this.previousUpdatesVisible = !this.previousUpdatesVisible;
    },
    sortTable(column) {
      if (this.tableSort.column === column) {
        this.tableSort.desc = !this.tableSort.desc;
      } else {
        this.tableSort = {
          column,
          desc: false,
        };
      }
    },
    async markerContent({ item }) {
      // TODO: Highlight quotation row in table while info box is open
      // Open up small box containing key information (basically same as table)
      // Link should point to full quotation
      const { data: supplier } = await this.$store.suppliers.getOverview(item.id);

      let content = [];
      if (supplier.footwearTypes) content.push(supplier.footwearTypes.map((ft) => ft.title).join(', '));
      if (supplier.processes) content.push(supplier.processes.map((p) => p.title).join(', '));

      let img = '';
      if (supplier.coverPhoto) img = imagePath(`images/${supplier.coverPhoto}`, 960, 250);

      const slug = supplier.slug;
      const title = supplier.title;

      return {
        content,
        img,
        link: {
          action: () => {
            this.$scrollTo(`#quotation-${item.quotationId}`, 500, { offset: -10 });
          },
          text: 'View info',
        },
        slug,
        title,
      };
    },
    async response(id, response) {
      await this.$store.quotations.updateQuotationResponse(this.request.id, id, response);
    },
  },
  mounted() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}

    this.ie = checkIfIE();
  },
  beforeDestroy() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/media-queries';
@import 'common/styles/text';
@import 'common/styles/variables';

.quotations-request {
  &__container {
    max-width: 86rem;
    margin-left: auto;
    margin-right: auto;
  }

  .request {
    &__heading {
      @include heading--large;
      text-align: center;
      max-width: 50rem;
      margin: 0.5em auto 0 auto;
    }

    &__subheading {
      @include subtitle;
      text-align: center;
      max-width: 50rem;
      margin: 0.125rem auto 0 auto;
    }

    &__kpis {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 1.125rem;

      @include mobile-only {
        &:after {
          content: '';
          width: 100%;
          order: 5;
        }
      }

      @include mobile-xs-only {
        display: none;
      }
    }

    &__kpi {
      // Fixes IE11 issue with centering flex content and max-width/height - selector from http://browserhacks.com/ ;
      @supports (-webkit-appearance: none) {
        max-width: 14rem;
      }

      margin-top: 1rem;
      order: 1;

      &:nth-child(n + 3) {
        order: 10;
      }

      .key-figure__value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-appearance: none) {
          max-width: 14rem;
        }
      }
    }

    &__details {
      user-select: none;
      display: grid;
      grid-template-columns: 5fr 3fr 3fr 3fr;
      grid-template-rows: auto auto auto auto auto 3.25rem;
      grid-template-areas:
        'image . . .'
        'image . . .'
        'image . . .'
        'image slider slider slider'
        'image . . .'
        'image . . .';
      column-gap: 3.5rem;
      margin-top: 4rem;

      @include mobile-to-tablet {
        grid-template-columns: 5fr 3fr 3fr;
        column-gap: 2rem;
        grid-template-rows: auto auto auto auto auto auto 1.125rem;
        grid-template-areas:
          'image . .'
          'image . .'
          'image . .'
          'image . .'
          'image . .'
          'image slider slider'
          '. . .';
      }

      @include mobile-only {
        grid-template-columns: auto minmax(14rem, auto);
        grid-template-rows: auto auto auto auto auto auto auto auto 1.125rem;
        grid-template-areas:
          '. image'
          '. image'
          '. image'
          '. image'
          '. image'
          '. .'
          '. .'
          'slider slider';
      }

      @include mobile-xs-only {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
      }
    }

    &__image {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      grid-area: image;
      order: 1;

      @include mobile-only {
        padding-bottom: 1.125rem;
      }

      @include mobile-xs-only {
        min-height: 15rem;
      }
    }

    &__field {
      margin: 1rem 0 0.5rem 0;
      order: 10;

      @include mobile-xs-only {
        margin-top: 0.5rem;
      }

      &--as-prefix {
        display: inline-block;
        width: 5rem;
        margin-right: 7%;
      }

      &--product {
        @include mobile-to-tablet {
          order: 2;
        }
      }

      &--numbers {
        @include mobile-to-tablet {
          order: 3;
        }
      }

      @include mobile-ls-min {
        &--kpi {
          display: none;
        }
      }

      // IE11 grid fixes
      @mixin ie11fixes($child: 1, $skip: 1 2 3 4 5 10) {
        @for $row from 1 through 3 {
          @for $column from 2 through 4 {
            @while index($skip, $child) != null {
              $child: $child + 1;
            }

            &:nth-child(#{$child}) {
              -ms-grid-column: ($column * 2 - 1);
              -ms-grid-row: $row;
            }

            $child: $child + 1;
          }
        }
      }

      @include ie11fixes;
    }

    &__field-label {
      @include overline;
    }

    &__field-value {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__slider-container {
      grid-area: slider;
      display: flex;
      order: 20;

      // TODO: REVIEW/REFACTOR Make this dynamic based on min-width of something instead? So that things wrap naturally? ;
      @include tablet-portrait-only {
        flex-direction: column;
      }

      @include mobile-xs-only {
        flex-direction: column;
      }
    }

    &__slider {
      flex-grow: 1;
      max-width: 30rem;
      margin: 1rem 0 -1.375rem 0;
      top: 0.5rem;
      order: 20;

      // Workaround for not being able to control below class/styling
      .vue-slider-component {
        opacity: 1;
      }

      @include desktop-max {
        top: 0.5625rem;
      }

      @include tablet-portrait-only {
        margin-top: 0;
        top: 0;
      }

      @include mobile-xs-only {
        margin-top: 0;
        top: 0;
      }
    }
  }

  .quotations-header {
    display: flex;
    margin-top: 6.5rem;

    @include mobile-to-tablet {
      margin-top: 4rem;
    }

    &__heading {
      // Based on (very similar) to home page -> category__heading style
      @include heading;
      display: inline-block;
      position: relative;
      padding: 0.625rem 0;
      margin: 0 auto 0 0;

      // Together with below, a lot of small adjustments to make it
      // better centered (due to uneven space before especially
      // capital B). The underline should be 1px before/after each
      // first/last letter;

      // Underline;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: -0.0625rem;
        bottom: (0.625rem - 0.25rem);
        height: 2px;
        background-color: $c-dark;
      }
    }

    &__status-container {
      display: flex;
      margin-top: 1.4375rem;
    }

    &__status-icons {
      display: flex;
      margin-right: 1rem;
    }

    &__status-icon {
      margin-right: 1rem;
      opacity: 0.47;

      &--smaller {
        svg {
          transform: scale(0.8);
        }
      }

      &--active {
        opacity: 1;
      }
    }

    &__status-text {
      font-size: 1.125em;
    }
  }

  .quotations-overview {
    display: flex;

    @include mobile-only {
      display: block;
    }

    &__overview {
      flex-basis: 0;
      flex-shrink: 1;
      flex-grow: 1;
      padding-top: 2.5rem;

      @include mobile-only {
        padding-top: 0.5rem;
      }
    }

    &__in-brief {
      display: flex;
      margin-top: 1.5rem;
    }

    &__best-price {
      margin: 0.5rem;
      flex-shrink: 0;
    }

    &__kpis {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 1.5rem;
      margin: 0 0 0.5rem 3.5rem;

      @include mobile-to-tablet {
        margin-left: 1.5rem;
      }
    }

    &__kpi {
      display: block;
      margin: 0.25rem 0 0.25rem 0;
    }

    &__updates {
      position: relative;
      margin-top: 1.5rem;
    }

    &__previous-updates {
      @include overline;
      font-size: 0.75em;
      font-weight: $bold-lato;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 2rem;
      margin: -0.5rem auto 0.625rem auto;
      text-align: center;
      cursor: pointer;
    }

    &__previous-updates-icon {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      fill: $c-dark;
      margin-left: 0.25rem;
      transition: transform 0.5s ease;

      &--open {
        transform: scaleY(-1);
      }
    }

    &__additional-content {
      flex-basis: 0;
      flex-shrink: 1;
      flex-grow: 1;
      margin-left: 4rem;

      @include mobile-to-tablet {
        margin-left: 2rem;
      }

      @include mobile-only {
        margin-left: 0;
      }
    }

    &__map {
      height: 23rem;
    }

    &__map-container {
      height: 23rem;
    }
  }

  .quotation-update__previous-container {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
  }

  .quotation-update {
    display: flex;
    padding: 1.25rem 0 1.25rem 0;

    @include mobile-only {
      padding: 1rem 0 1rem 0;
    }

    &__author {
      margin: 0.25rem 1rem 0 1rem;
      width: 4.5rem;
      text-align: center;
      flex-shrink: 0;
    }

    &__author-image-container {
      height: 4.5rem;
      width: 100%;
      border-radius: 50%;
      overflow: hidden;
    }

    &__author-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__author-name {
      @include caption--small;
      line-height: 1; // TODO: Review if we really should break convention here or if we should do something within caption-small;
      word-spacing: 4.5rem;
    }

    &__content {
      margin: 0 0 0 1rem;
    }

    &__content-header-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      @include mobile-to-tablet {
        flex-wrap: wrap;
      }
    }

    &__content-header {
      @include heading--mini;
      font-weight: $bold-montserrat;
      margin-top: 0;
      margin-right: 1rem;
    }

    &__content-date {
      @include caption;
      flex-shrink: 0;
    }

    &__content-text {
      // REVIEW
      p:first-child {
        margin-top: 0.25rem;
      }

      p:last-child {
        margin-bottom: 0.25rem;
      }
    }
  }

  .quotations-table {
    display: grid;
    // Based on quotations/index table;
    column-gap: 2.5rem;
    /* autoprefixer: ignore next */
    grid-template-columns:
      minmax(12rem, 6fr) minmax(min-content, 2fr) minmax(min-content, 2fr) minmax(min-content, 2fr) minmax(
        min-content,
        2fr
      )
      auto;
    margin-top: 4.75rem;

    @include mobile-to-tablet {
      column-gap: 1rem;
      /* autoprefixer: ignore next */
      grid-template-columns:
        minmax(12rem, 6fr) minmax(min-content, 2fr) minmax(min-content, 2fr) minmax(min-content, 2fr)
        auto;
      margin-top: 3.75rem;
    }

    @include mobile-only {
      /* autoprefixer: ignore next */
      grid-template-columns: auto minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
      grid-column: 1 / 3;
    }

    @include mobile-xs-only {
      /* autoprefixer: ignore next */
      grid-template-columns: auto minmax(min-content, auto) minmax(min-content, auto);
    }

    &__th {
      @include overline;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.25rem 0.25rem 0.25rem 0.25rem;
      margin: 0 -0.25rem 0 -0.25rem;
      align-items: center;
      cursor: pointer;

      @include mobile-to-tablet {
        &:nth-child(6n - 2) {
          display: none;
        }
      }

      @include mobile-only {
        &:nth-child(6n - 0) {
          display: none;
        }
      }

      @include mobile-xs-only {
        &:nth-child(6n - 1) {
          display: none;
        }
      }
    }

    &__th-sort-icon {
      display: inline-block;
      height: 0.75rem;
      width: 0.75rem;
      fill: $c-dark;
      margin-left: 0.125rem;
      transform: scaleY(0);
      transition: transform 0.5s ease;

      &--active {
        transform: scaleY(1);
      }

      &--desc {
        transform: scaleY(-1);
      }
    }

    &__td {
      display: block; // IE11 fix;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.375rem 0 0.375rem 0;

      &:nth-child(6n + 1) {
        padding-left: 1.5rem;
      }

      @include mobile-to-tablet {
        &:nth-child(6n + 4) {
          display: none;
        }
      }

      @include mobile-only {
        &:nth-child(6n + 6) {
          display: none;
        }
      }

      @include mobile-xs-only {
        &:nth-child(6n + 5) {
          display: none;
        }
      }
    }

    &__td-icon {
      // Based on quotations/index table icons;
      position: absolute;
      top: (0.375rem + 0.125rem);
      left: -1px;
      height: 1.125rem;
      width: 1.125rem;
      fill: $c-dark;
    }
  }

  .quotations-container {
    display: grid;
    /* autoprefixer: ignore next */
    grid-template-columns: 50% 50%;
    margin-top: 5rem;
    margin-left: -1rem;
    margin-right: -1rem;

    @include mobile-to-tablet {
      margin-top: 4rem;
    }

    @include mobile-only {
      display: block;
    }
  }
}
</style>
