var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabs" }, [
    _c(
      "div",
      { staticClass: "tabs__navigation" },
      _vm._l(_vm.tabs, function (tab, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "tabs__tab",
            class: { "tabs__tab--active": index === _vm.active },
            attrs: { href: "#", title: tab.title },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.click(tab, index)
              },
            },
          },
          [_vm._v(_vm._s(tab.title))]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tabs__content-container" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }