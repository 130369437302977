<template>
  <div class="tabs__tab-content">
    <slot v-if="active"></slot>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// Sub-component tightly coupled with tabs.vue
// See tabs.vue for styling
</style>
